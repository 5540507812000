.interview-section {
 padding: var(--spacing-large) var(--spacing-medium);
 background-color: var(--color-lightest);
 color: var(--color-darkest);
 width: 100%;
}

.interview-grid {
 max-width: 768px;
 margin: 0 auto;
 display: flex;
 align-items: center;
 gap: var(--spacing-large);
}

.image-column {
 display: flex;
 justify-content: center;
 align-items: center;
}

.image-container {
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 transition: transform 0.2s ease;
}

.image-container:hover {
 transform: scale(1.05);
}

.interview-image {
 width: 100%;
 height: auto;
 border-radius: var(--border-radius);
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 padding: 3px;
 background-color: white;
 border: 1px solid rgba(0, 0, 0, 0.1);
}

.text-column {
 padding: var(--spacing-medium);
}

.mobile-center {
 text-align: left;
}

@media (min-width: 769px) {
 .image-column {
   flex: 1.5;
 }
 .text-column {
   flex: 1;
 }
}

@media (max-width: 768px) {
 .interview-grid {
   flex-direction: column;
 }
 
 .image-container {
   max-width: 100%;
 }

 .text-column {
   order: 1;
 }
 
 .image-column {
   order: 2;
 }
 
 .mobile-center {
   text-align: center;
 }

 .interview-button {
    display: flex;
    justify-content: center;
    align-items: center;
 }
}