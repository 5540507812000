/* JobList component styles */

.job-list {
  display: grid;
  grid-template-columns: 350px;
  gap: 24px;
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

@media (min-width: 768px) {
  .job-list {
    grid-template-columns: repeat(2, 350px);
  }
}

@media (min-width: 1200px) {
  .job-list {
    grid-template-columns: repeat(3, 350px);
  }
}