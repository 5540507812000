/* ================================
   GLOBAL CSS (Cleaned Up)
   ================================ */

/* Global Variables */
:root {
  --color-darkest: #0C0D0F;
  --color-dark: #2C2F33;
  --color-medium: #575B61;
  --color-light: #898E94;
  --color-lightest: #F4F5F6;

  --color-primary: #007EF4;
  --color-secondary: #00C9A7;
  --color-accent-color: #00C9A7;
  --color-card-background: #F4F5F6;

  --color-text-default: var(--color-darkest);
  --color-text-on-dark: var(--color-lightest);
  --color-text-on-light: var(--color-darkest);
  --color-text-on-primary: var(--color-lightest);
  --color-text-on-secondary: var(--color-dark);

  --color-error: #e74c3c;
  --color-success: #27ae60;
  --color-warning: #f39c12;

  --color-hero-gradient: #22262d;

  --shadow-hover: 0 6px 12px rgba(204, 231, 255, 0.8);

  --spacing-small: 0.5rem;
  --spacing-medium: 1rem;
  --spacing-large: 2rem;

  --font-family-base: 'museo-sans', sans-serif;
  --font-size-small: 0.875rem;
  --font-size-medium: 1rem;
  --font-size-large: 1.5rem;

  --border-radius: 4px;

  --transition-default: all 0.3s ease-in-out;
}

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overscroll-behavior: auto;
  scroll-behavior: smooth;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

body {
  font-family: var(--font-family-base);
  background-color: var(--color-dark);
  color: var(--color-text-on-dark);
}

.app-container {
  min-height: 100vh;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}
