/* Books.css - Book-specific styles */
.books-section {
  background-color: var(--color-lightest);
  width: 100%;
  margin: 0 auto;
  padding: var(--spacing-large) 0;
  text-align: center;
}


.book-card {
  width: 180px;
  background-color: var(--color-light);
}

.book-cover {
  width: 100%;
  aspect-ratio: 2/3;
  object-fit: cover;
  border-radius: calc(var(--border-radius) / 2);
  margin-bottom: var(--spacing-small);
}

.book-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.book-card .book-title {
  font-weight: bold;
  color: var(--color-text-on-dark);
  margin: 0;
  text-align: center;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* Ensure the text has a container to overflow from */
}

.book-card .book-author {
  color: var(--color-text-on-dark);
  margin: 0;
  text-align: center;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.book-status {
  width: auto;
  min-width: 100px;
  padding: 4px 12px;
  border-radius: calc(var(--border-radius) / 2);
  font-family: var(--font-family-base);
  font-size: calc(var(--font-size-small) * 0.875);
  font-weight: 600;
  text-align: center;
  margin-top: var(--spacing-small);
}

.reading-status {
  background-color: var(--color-primary);
  color: var(--color-lightest);
}

.completed-status {
  background-color: var(--color-secondary);
  color: var(--color-text-on-dark);
}

@media (min-width: 768px) {
  .books-section {
    padding: calc(var(--spacing-large) * 1.5) 0;
  }

  .book-card {
    width: 200px;
  }
}