/* JobTile component styles */

.job-tile {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-in-out;
  width: 350px;
  height: 150px;
}

.job-tile.visible {
  opacity: 1;
  transform: translateY(0);
}

.tile-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-card-background);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  height: 100%;
  width: 100%;
}

.job-image-wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 60px;
  height: 60px;
}

.job-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 4px;
}

.company-name-wrapper {
  height: auto;
  margin-right: 80px;
  margin-top: 8px;
  padding-left: 16px;
  overflow: hidden;
}

.company-name {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.2;
  color: var(--color-text-default);
  text-align: left;
  white-space: normal;
}

.job-details {
  margin-top: auto;
  padding: 0 16px;
  text-align: left;
  position: absolute;
  top: 65px;
  width: calc(100% - 32px);
}

.job-title {
  font-size: 15px;
  font-weight: normal;
  color: var(--color-text-default);
  margin-bottom: 4px;
}

.domain {
  font-size: 15px;
  font-weight: normal;
  color: var(--color-text-default);
}