/* Hobbies.css - Hobby-specific styles */
.hobbies-section {
  background-color: var(--color-highlight);
  color: var(--color-text-primary);
  padding: var(--spacing-large) 0;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  position: relative;
}


.hobby-card {
  width: 140px;
  background-color: transparent !important;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-small);
  border: none;
}


.hobby-icon-wrapper {
  background-color: var(--color-lightest);
  border-radius: 50%;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hobby-icon {
  color: var(--color-primary);
  width: 48px;
  height: 48px;
}

.hobby-name {
  font-size: var(--font-size-small);
  font-weight: 1000;
  color: var(--color-text-primary);
  text-align: center;
}

.beta-tag {
  display: inline-block;
  background-color: var(--color-accent);
  color: var(--color-card-background);
  font-size: 0.625rem;
  padding: 0.125rem 0.375rem;
  border-radius: var(--border-radius);
  margin-left: 0.25rem;
  vertical-align: top;
}

@media (min-width: 768px) {
  .hobbies-section {
    padding: var(--spacing-large) 0;
  }

  .hobby-card {
    width: 160px;
  }
}