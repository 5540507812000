/* Hero.css */

.hero-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent horizontal scrolling */
  background-color: var(--color-hero-gradient);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* Includes padding and border in height calculation */
  pointer-events: auto; /* Ensure interactions are not blocked */
}

.hero-container .MuiTypography-root.hero-title {
  margin-top: 7% !important;
}

/* Image Wrapper */
.hero-image-wrapper {
  position: relative;
  width: 100%;
  height: 100%; /* Matches the hero container height */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Actual Image */
.hero-image {
  height: 100%; /* Matches the hero container height */
  width: auto; /* Maintains aspect ratio */
  display: block;
  margin: 0 auto;
  object-fit: cover; /* Ensures the image fills the container */
}

/* Gradient Overlay */
.hero-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; /* Just above the image */
  pointer-events: none;
  background: linear-gradient(
      to right,
      var(--color-hero-gradient) 20%,
      rgba(0, 0, 0, 0.2) 45%,
      rgba(0, 0, 0, 0.2) 30%,
      var(--color-hero-gradient) 100%
    ),
    linear-gradient(
      to left,
      var(--color-hero-gradient) 20%,
      rgba(0, 0, 0, 0.2) 45%,
      rgba(0, 0, 0, 0.2) 30%,
      var(--color-hero-gradient) 100%
    ),
    linear-gradient(
      to bottom,
      var(--color-hero-gradient) 0%,
      rgba(0, 0, 0, 0.1) 10%,
      var(--color-hero-gradient) 100%
    ); /* Gradient to fade edges of the image */
}

/* Content inside the overlay */
.hero-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3; /* Ensures content is above the overlay */
  color: var(--color-secondary-bg);
  text-align: center;
  box-sizing: border-box;
  pointer-events: auto; /* Ensure interactions are enabled */
}

.hero-container .hero-title {
  margin-top: 7%;
  font-size: 4vw; /* Default for larger screens */
  font-weight: bold;
  color: var(--color-card-background);
  z-index: 5;
  line-height: 1.1; /* Prevent large spacing between wrapped lines */
  white-space: normal; /* Allow wrapping if necessary */
  pointer-events: none; /* Prevent blocking interactions */
  margin-top: none;
  margin-bottom: 2%;
}

.hero-content .hero-tagline {
  font-size: 2vw; /* Default for larger screens */
  font-weight: bold;
  color: var(--color-card-background);
  z-index: 5;
  line-height: 1.1; /* Prevent large spacing between wrapped lines */
  white-space: normal; /* Allow wrapping if necessary */
  pointer-events: none; /* Prevent blocking interactions */
}


/* Subtitle and Pillars Container */
.bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around; /* Adjust spacing within the container */
  align-items: center;
  position: absolute;
  bottom: 8%; /* Adjust position according to your layout needs */
  width: 100%;
  z-index: 5;
  color: white;
}

/* Individual Pillar Text Styling */
.bottom-container .personal-text {
  font-size: 1.5rem !important; /* Adjust font size to be consistent with design */
  color: white !important;
  display: block;
  opacity: 0;
  animation: fadeIn 0.6s ease-in-out forwards;
  margin: 1rem 0; /* Space between pillar texts */
}

.pillar-fade-in-0 {
  animation-delay: 0.2s;
}

/* Fade-in Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .hero-container .hero-title {
    font-size: 10vw; /* Adjusted for smaller screens */
    margin-top: 15%;
    margin-bottom: 5%;
  }

  .hero-container .MuiTypography-root.hero-title {
    margin-top: 15% !important;
  }

  .hero-button {
    font-size: 16px; 
    padding: 0.75rem 1.5rem; 
    margin-bottom: 3;
  }

  .hero-content .hero-tagline {
    font-size: 4vw; /* Default for larger screens */
  }

  .bottom-container {
    bottom: 8%;
  }

  .bottom-container .personal-text {
    font-size: 5vw; /* Adjust pillar text size */
    margin-right: 10px;
    margin-left: 10px;
  }

  /* Gradient Overlay Adjustments */
  .hero-gradient-overlay {
    background: linear-gradient(
        to right,
        var(--color-hero-gradient) 0%,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 0%,
        var(--color-hero-gradient) 100%
      ),
      linear-gradient(
        to left,
        var(--color-hero-gradient) 0%,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 0%,
        var(--color-hero-gradient) 100%
      ),
      linear-gradient(
        to bottom,
        var(--color-hero-gradient) 0%,
        rgba(0, 0, 0, 0) 0%,
        var(--color-hero-gradient) 100%
      ); /* Gradient to fade edges of the image */
  }
}