.articles-section {
  text-align: center;
  padding: var(--spacing-large) var(--spacing-medium);
  background-color: var(--color-darkest);
  color: var(--color-lightest);
  width: 100%;
  margin: 0 auto;
}

.articles-grid {
  max-width: 1200px;
  margin: 0 auto;
}

.articles-empty {
  font-size: var(--font-size-medium);
  margin-top: var(--spacing-large);
}

.article-card {
  display: flex;
  width: 100%;
  max-width: 640px;
  height: auto;
  margin: 0 auto 24px;
  background: var(--color-dark);
  border-radius: 4px;
  overflow: hidden;
}

.article-image {
  width: 267px;
  height: 150px;
  object-fit: contain;
  flex-shrink: 0;
  background-color: var(--color-dark);
}

.article-content {
  padding: 12px 12px 12px 18px;
  text-align: left;
  flex: 1;
}

.article-title {
  padding-bottom: 10px;
}

.article-image-placeholder {
  width: 267px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light);
  font-weight: var(--font-weight-bold);
  text-align: center;
}

@media (max-width: 768px) {
  .article-card {
    flex-direction: column;
  }

  .article-title {
    text-align: center;
  }
  
  .article-image,
  .article-image-placeholder {
    width: 100%;
    height: 150px;
  }
}