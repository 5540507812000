.resume-container {
  width: 100%;
  padding: 3rem 0;
  background-color: var(--color-primary-text);
  color: var(--color-secondary-bg);
  text-align: center;
 }
 
 .resume-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0 24px;
 }
 
 .resume-description {
  margin: 0 auto 1.25rem;
  line-height: 1.6;
  max-width: 350px;
 }
 
 @media (min-width: 768px) {
  .resume-description {
    max-width: 700px;
  }
 }
 
 @media (min-width: 1200px) {
  .resume-description {
    max-width: 1100px;
  }
 }