/* Carousel.css - Specific to Carousel */
.carousel-container {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  position: relative;
}

.carousel {
  display: flex;
  gap: var(--spacing-large);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0;
  margin: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Hide scrollbar */
.carousel::-webkit-scrollbar {
  display: none;
}

/* Base item styles */
.carousel > * {
  flex: 0 0 auto;
  padding: var(--spacing-medium);
}

/* Edge spacing */
.carousel > :first-child {
  margin-left: var(--spacing-large);
}

.carousel > :last-child {
  margin-right: var(--spacing-large);
}

/* Base card styles */
.carousel-card {
  background: var(--color-lightest);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-hover);
  transition: var(--transition-default);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.carousel-card img {
  width: 100%;
  height: auto;
  max-height: 220px;
  object-fit: cover;
  border-radius: calc(var(--border-radius) / 2);
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .carousel {
    gap: var(--spacing-medium);
  }

  .carousel > :first-child {
    margin-left: var(--spacing-medium);
  }

  .carousel > :last-child {
    margin-right: var(--spacing-medium);
  }

  .carousel-card img {
    max-height: 180px;
  }
}