.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: var(--color-darkest) ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .sticky-header.visible {
    transform: translateY(0);
    background-color: var(--color-darkest) ;
  }
  
  .header-name {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
  }
  
  .header-contact-button:active {
    transform: scale(0.98);
  }
  
  @media (max-width: 768px) {
    .sticky-header {
      padding: 0 1rem;
    }
  
    .header-name {
      font-size: 24px !important;
    }
  
    .header-contact-button {
      padding: 0.4rem 1rem;
      font-size: 0.8rem;
    }
  }