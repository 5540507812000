/* LifeMap.css */

/* Horizontal scrolling container */
.life-map {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Enable snapping on scroll */
    width: 100%; /* Full width */
    padding: 0;
    margin: 0;
    gap: 0; /* No gaps between tiles */
  }
  
  /* Hide scrollbar for a clean look */
  .life-map::-webkit-scrollbar {
    display: none;
  }
  
  /* Each city tile */
  .city-tile {
    flex: 0 0 calc(100% / 1.75); /* Each tile is 1/1.75th of the viewport width */
    height: calc(100vw / 1.75); /* Keep tiles square */
    max-width: 350px; /* Add a max width on desktop */
    max-height: 350px; /* Add a max height on desktop */
    background-size: cover;
    background-position: center;
    scroll-snap-align: start;
    position: relative;
    display: flex;
    justify-content: center; /* Center overlay horizontally */
    align-items: center; /* Center overlay vertically */
    margin: auto; /* Ensure the tiles are centered within the flex row */
  }
  
  /* Overlay for text readability */
  .city-overlay {
    background-color: rgba(0, 0, 0, 0.7); /* More opaque shadow */
    padding: 10px 40px; /* Increased left and right padding */
    text-align: center;
    max-width: 70%; /* Centered overlay, smaller than the tile */
  }
  
  /* City name */
  .city-name {
    font-size: clamp(1rem, 3vw, 2rem); /* Responsive font size for city name */
    font-weight: bold;
    color: var(--color-lightest);
    text-transform: uppercase;
    margin: 0;
  }
  
  /* City years */
  .city-years {
    font-size: clamp(0.8rem, 2vw, 1.5rem); /* Responsive font size for years */
    color: white;
    margin: 0;
  }